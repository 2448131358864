import React from "react";
import { Helmet } from "react-helmet";
import Header from "./Header";
import Footer from "./Footer";

export default function Layout({ children }) {
  return (
    <>
      <Helmet>
        <meta property="og:url" content="https://www.lookeando.com/" />
        {/* ... todos los otros metadatos ... */}
        <link rel="icon" href="/L_logo.png" />
        <link rel="shortcut icon" href="/L_logo.png" />
        <title>Lookeando</title>
      </Helmet>
      <Header />
      {children}
      <Footer />
    </>
  );
}
