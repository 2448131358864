import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./Footer.module.css";

const Footer = () => {

  return (
    <>
      <footer className={styles.footer}>
        <Container>
          <Row className={styles.footerSections}>
            <Col>
              <div className={styles.footerItem}>
                <h3 className={styles.footerTitle}>Lookeando</h3>
                <ul className={styles.footerList}>
                  <li>
                    <Link to="https://app.lookeando.com/" target="_blank">Usar la aplicación</Link>
                  </li>
                  <li>
                    <Link to="https://wa.me/3416552804?text=Hola!%20Me%20gustaría%20añadir%20mi%20negocio%20en%20la%20aplicación." target="_blank">Registra tu negocio</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col>
              <div className={styles.footerItem}>
                <h3 className={styles.footerTitle}>FAQs</h3>
                <ul className={styles.footerList}>
                  <li>
                    <Link to="/faqs#app">Sobre nosotros</Link>
                  </li>
                  <li>
                    <Link to="/faqs#user">Para el usuario</Link>
                  </li>
                  <li>
                    <Link to="/faqs#commerce">Para el comercio</Link>
                  </li>
                  <li>
                    <Link to="/faqs#privacy">Privacidad</Link>
                  </li>
                  <li>
                    <Link to="/faqs#terms">Términos</Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>

          <Row>
            <div className={styles.footerBottom}>
              <img
                src="/images/header/logo_360x90.png"
                alt="Logo de Lookeando"
                width={245}
                height={64}
              />
            </div>
          </Row>
          <Row>
            <div className={styles.footerBottom}>
              <p className={styles.footerText}>&copy; 2023 Lookeando</p>
            </div>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
