import { Container } from "react-bootstrap";
import Service from "./Service";
import styles from "./Advantages.module.css";

const Advantages = () => {

  const data = [
    {
      id: 1,
      icon: "/images/home/icons/service1.png",
      title: "Aplicación Todo-en-Uno",
      description: "Experimenta la comodidad de tener todas tus opciones de belleza y estilo en una sola plataforma. Reserva, explora y califica, todo desde 'Lookeando'.",
    },
    {
      id: 2,
      icon: "/images/home/icons/service2.png",
      title: "Información Detallada",
      description: "Ofrecemos detalles precisos de cada peluquería y servicio de belleza, garantizando que siempre tomes decisiones informadas basadas en tus preferencias.",
    },
    {
      id: 3,
      icon: "/images/home/icons/service3.png",
      title: "Descubre lo Inesperado",
      description: "Aventúrate más allá de tus lugares habituales y descubre nuevos establecimientos y servicios que te sorprenderán y revitalizarán tu estilo.",
    },
  ];

  return (
    <section id="services" className={styles.section}>
      <Container>
        <div className={styles.heading}>
          <h2>Su nueva forma de cuidar tu estilo ha llegado</h2>
          <p>Te conectamos con peluquerías y lugares de belleza cerca de ti, para que descubras y experimentes nuevos estilos.</p>
        </div>
        <div className={styles.contentWrapper}>
          {data?.map((item) => (
            <Service key={item.id} item={item} />
          ))}
        </div>
      </Container>
    </section>
  );
};

export default Advantages;
