import React from 'react';
import Banner from './home/Banner';
import Advantages from './home/Advantages';
import OtherServices from './home/OtherServices';
import Feedback from './home/Feedback';

const MainPage = () => {
  return (
    <>
      <Banner />
      <Advantages />
      <OtherServices />
      <Feedback />
    </>
  );
}

export default MainPage;
