import { Row, Col, Container, Button } from "react-bootstrap";
import styles from "./Feedback.module.css";

const Feedback = () => {
  return (
    <section className={styles.section}>
      <Container>
        <Row className={styles.contentWrapper}>
          <Col lg={8}>
            <div className={styles.heading}>
              <h2>¿Te gusta nuestro servicio? Ayudanos a mejorar</h2>
              <p>Valoramos los comentarios de los clientes como una parte fundamental de nuestro negocio.</p>
              <p>Siempre estamos buscando formas de mejorar nuestro servicio y nos encantaría escucharlo de vos.</p>
            </div>
          </Col>
          <Col lg={4} className={styles.contactCol}>
            <div className={styles.heading}>
              <h2> Contactanos </h2>
            </div>
            <a href="mailto:fedenavos@gmail.com?subject=Sugerencias para Lookeando" target="_blank" rel="noreferrer">
              <Button variant="success" className={styles.btn}>
                Enviar sugerencias
              </Button>
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Feedback;
