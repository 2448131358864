import React from "react";
import styles from "./Faq.module.css";

export default function FAQ({ faq, index, toggleFAQ }) {
  return (
    <div
      className={`${styles.faq} ${faq.open ? styles.open : ""}`}
      key={index}
      onClick={() => toggleFAQ(index)}
    >
      <div className={styles.faqQuestion}>Preguntas</div>
      <div className={styles.faqAnswer}>Answer</div>
    </div>
  );
}
