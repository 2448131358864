import React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./Header.module.css";


// const RegisterButton = () => {
//   return (
//     <svg
//       width="24"
//       height="24"
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox="0 0 24 24"
//       cursor="inherit"
//       role="img"
//       title="Agrega tu negocio"
//       focusable="false"
//       className="sc-ftvSup ivFvRT"
//     >
//       <path
//         // eslint-disable-next-line max-len
//         d="M5.623 3c-.724 0-1.354.495-1.523 1.198L3.073 8.474a2.587 2.587 0 0 0 .972 2.683v7.138c0 .895.727 1.62 1.623 1.62h6.54A4.818 4.818 0 0 0 16.18 22 4.814 4.814 0 0 0 21 17.192c0-1.13-.391-2.17-1.045-2.99v-3.045a2.587 2.587 0 0 0 .972-2.683L19.9 4.198A1.565 1.565 0 0 0 18.377 3H5.623Zm-.28 1.496a.287.287 0 0 1 .28-.22h12.754c.133 0 .248.092.28.22l1.027 4.276a1.316 1.316 0 0 1-1.283 1.622c-.73 0-1.32-.59-1.32-1.315v-.262a.639.639 0 0 0-1.278 0 1.58 1.58 0 0 1-1.582 1.577 1.58 1.58 0 0 1-1.582-1.577.639.639 0 0 0-1.278 0 1.58 1.58 0 0 1-1.582 1.577 1.58 1.58 0 0 1-1.582-1.577.639.639 0 0 0-1.279 0v.262c0 .726-.59 1.315-1.319 1.315a1.317 1.317 0 0 1-1.283-1.622l1.028-4.276Zm4.436 7.175c.896 0 1.697-.412 2.221-1.056a2.857 2.857 0 0 0 2.221 1.056c.858 0 1.627-.377 2.151-.973a2.595 2.595 0 0 0 2.304.959v1.42a4.805 4.805 0 0 0-2.496-.694 4.814 4.814 0 0 0-4.597 6.255H5.668a.344.344 0 0 1-.344-.343v-6.638a2.595 2.595 0 0 0 2.304-.959 2.857 2.857 0 0 0 2.15.973Zm2.86 5.52a3.536 3.536 0 0 1 3.541-3.531 3.536 3.536 0 0 1 3.541 3.532 3.536 3.536 0 0 1-3.54 3.531 3.536 3.536 0 0 1-3.542-3.531Z"
//         fill="#fff"
//       />
//       <path
//         d="M16.21 14.541a.639.639 0 0 0-.638.638v1.343h-1.408a.639.639 0 1 0 0 1.277h1.408v1.403a.639.639 0 0 0 1.278 0V17.8h1.346a.639.639 0 1 0 0-1.277H16.85V15.18a.639.639 0 0 0-.64-.638Z"
//         fill="#fff"
//       />
//     </svg>
//   );
// }

const Header = () => {
  return (
    <section id="navbar">
      <Navbar expand="lg" fixed="top" className={styles.header} >
        <Container>
          <Navbar.Brand as={Link} to="/" className={styles.logoContainer}>
            <img
              src="/images/header/logo_360x90.png"
              alt="Lookeando Logo"
              width={245}
              height={64}
              className={styles.logo}
            />
          </Navbar.Brand>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Item className={styles.item}>
                <Nav.Link as={Link} to="https://wa.me/3416552804?text=Hola!%20Me%20gustaría%20añadir%20mi%20negocio%20en%20la%20aplicación." className={styles.link} target="_blank">
                  Registra tu negocio
                  {/* <RegisterButton /> */}
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item className={styles.item}>
                <Nav.Link as={Link} to="/faqs" className={styles.link} onClick={toggleExpanded}>
                  Preguntas frecuentes
                </Nav.Link>
              </Nav.Item> */}
              <div className={styles.buttonWrapper}>
                <a href="https://app.lookeando.com" target="_blank" rel="noopener noreferrer">
                  <button className={styles.appButton}>Abrir aplicación</button>
                </a>
              </div>
              {/* Puedes agregar más elementos de navegación aquí. */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </section>
  );
};

export default Header;
