import React, { useState } from "react";
import { Container } from "react-bootstrap";
import styles from "./Faqs.module.css";
import Faq from "../components/Faq";

export default function Faqs() {
  // Questions about the Gather app
  const [faqsApp, setFaqsApp] = useState({
    title: "App",
    questions: [
      {
        question: "¿Cómo funciona la app?",
        answer: "Aquí va la respuesta...",
        open: false,
      },
      {
        question: "¿Es gratuita?",
        answer: "Aquí va la respuesta...",
        open: false,
      },
      {
        question: "¿Es gratuita?",
        answer: "Aquí va la respuesta...",
        open: false,
      },
      {
        question: "¿Es gratuita?",
        answer: "Aquí va la respuesta...",
        open: false,
      },
      {
        question: "¿Es gratuita?",
        answer: "Aquí va la respuesta...",
        open: false,
      },
      {
        question: "¿Es gratuita?",
        answer: "Aquí va la respuesta...",
        open: false,
      },
    ],
  });

  // Similar structure for faqsTrips, faqsPayments, faqsPrivacy, faqsTerms...

  const toggleFAQ = (index, section) => {
    // Similar logic to the one you have for toggling FAQs...
  };

  return (
    <div>
      <head>
        <title>Gather - FAQs</title>
        <meta name="description" content="Frequently Asked Questions about Gather." />
        <link rel="icon" href="/favicon.ico" />
      </head>
      <Container className={styles.container}>
        <aside className={styles.questionsSidebar}>
          <h2>FAQs</h2>
          <ul>
            <li>
              <a href="#app">App</a>
            </li>
            {/* Similar links for other sections... */}
          </ul>
        </aside>

        <div className={styles.faqs}>
          <div className={styles.faqGroup}>
            <h2 id="app" className={styles.faqGroupTitle}>
              Próximamente
            </h2>
            {faqsApp.questions.map((faq, i) => (
              <Faq
                faq={faq}
                index={i}
                key={i}
                toggleFAQ={() => toggleFAQ(i, faqsApp.title)}
              />
            ))}
          </div>

          {/* Similar structure for other sections... */}
        </div>
      </Container>
    </div>
  );
}
