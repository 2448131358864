import { Container } from "react-bootstrap";
import styles from "./Banner.module.css";

const Banner = () => {
  return (
    <section id="home" className={styles.section}>
      <Container className={styles.container}>
        <div className={styles.contentWrapper}>
          <div className={styles.heading}>
            <h2>Tu look, tu elección</h2>
            <p>Te facilitamos encontrar el lugar perfecto para atender tus necesidades de belleza y bienestar</p>
          </div>
          <div as="figure" className={styles.illustration}>
            <div className={styles.buttonWrapper}>
              <a href="https://app.lookeando.com" target="_blank" rel="noopener noreferrer">
                <button>Abrir aplicación</button>
              </a>
            </div>
            <img
              src="/images/backgrounds/banner-lookeando-removebg.webp"
              alt="Ilustración de Lookeando"
              width={882}
              height={588}
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Banner;
