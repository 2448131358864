import Service from "./Service";
import { Container } from "react-bootstrap";
import styles from "./OtherServices.module.css";

const OtherServices = () => {

  const data = [
    {
      id: 1,
      icon: "/images/home/icons/barber_black.png",
      title: "Barberías",
      description: "Encuentra el corte masculino que más se adecúa a tu estilo.",
    },
    {
      id: 2,
      icon: "/images/home/icons/hairdresser_black.png",
      title: "Peluquerías",
      description: "Renueva tu look con profesionales destacados.",
    },
    {
      id: 3,
      icon: "/images/home/icons/manicure_black.png",
      title: "Manicura y pedicura",
      description: "Luce unas manos y pies impecables con nuestros servicios especializados.",
    },
    {
      id: 4,
      icon: "/images/home/icons/massage_black.png",
      title: "Relajación y Depilación",
      description: "Disfruta de una piel suave y libre de vello. Relájate y libera tensiones con sesiones especializadas.",
    },
    {
      id: 5,
      icon: "/images/home/icons/tatoo_black.png",
      title: "Tatuajes y Piercings",
      description: "Exprésate a través del arte en tu piel con profesionales certificados.",
    },
    {
      id: 6,
      icon: "/images/home/icons/stetic_black.png",
      title: "Belleza y Cuidado Facial",
      description: "Realza tu belleza natural con tratamientos y servicios diseñados para tu cuidado facial.",
    },
  ];

  return (
    <section id="who-can-use" className={styles.section}>
      <Container>
        <div className={styles.heading}>
          <h2>¿Qué puedes encontrar en Lookeando?</h2>
          <p>Descubre la variedad de servicios de belleza y estética que te ofrecemos.</p>
        </div>
        <div className={styles.contentWrapper}>
          {data?.map((item) => (
            <Service key={item.id} item={item} service={true} />
          ))}
        </div>
      </Container>
    </section>
  );
};
export default OtherServices;
