import './App.css';
import Layout from './components/layout/Layout';
import { SSRProvider } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import MainPage from './components/MainPage';
import Faqs from './components/Faqs';


function App() {
  return (
    <SSRProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/faqs" element={<Faqs />} />
          </Routes>
        </Layout>
      </Router>
    </SSRProvider>
  );
}

export default App;
