import styles from "./Service.module.css";

const Service = ({ item, service=false }) => {
  return (
    <div className={styles.serviceItem}>
      <figure className={styles.figure}>
        <img
          src={item?.icon}
          alt={`Icono de servicio - ${item?.title}`}
          width={88}
          height={88}
        />
      </figure>
      <div className={service ? styles.content : styles.service }>
        <h3>{item?.title}</h3>
        <p>{item?.description}</p>
      </div>
    </div>
  );
};

export default Service;
